import React from 'react';

import { Link } from "gatsby";
import EmailPopup from "../components/email-popup";
import Navbar from "../components/navbar";
import Footer from '../components/footer';
import Seo from '../components/seo'

const Offer = () => {

  return (
    <div id="main_content">
      <Seo title='Special Offer'/>
      <Navbar dark={true} />
      <EmailPopup />

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Special Offer</h1>

            <ul className="bradcurmed">
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>
              <li>Special Offer</li>
            </ul>
          </div>
        </div>
        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>
        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section className="spec-offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title style-two">
                <h2 className="big-title wow pixFadeUp">
                  Get your app now!
                </h2>

                <p className="wow pixFadeUp" data-wow-delay="0.3s" style={{ marginBottom: '50px' }}>
                  Create your e-learing course without any IT,
                  developer or UX/UI designer knowledge
                </p>
              </div>

              <Link
                to="/contact"
                className="pix-btn wow pixFadeUp"
                data-wow-delay="0.5s"
              >
                Contact us
              </Link>
            </div>

            <div className="spec-offer-feature-image">
              <div className="image-one" data-parallax='{"y" : 20}'>
                <img
                  src="images/screenshots/screenshot-2-min.webp"
                  className="wow pixFadeDown rounded shadow"
                  alt="spec-offer"
                />
              </div>

              <div className="image-two" data-parallax='{"y" : -20}'>
                <img
                  src="images/screenshots/screenshot-10-min.png"
                  className="wow pixFadeDown rounded shadow"
                  data-wow-delay="0.3s"
                  alt="spec-offer"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="revolutionize what-will-section">
        <div className="bg-angle-what">
          <div className="container">
            <div className="section-title style-two dark-title text-center" style={{ marginBottom: '55px' }}>
              <h2 className="what-will-title wow pixFadeUp" data-wow-delay="0.3s">
                What will you get? <br />

              </h2>
            </div>

            <div className="row text-center wow pixFadeUp" data-wow-delay="0.3s">
              <div className="col-lg-3">
                <h2 className="title what-will-big-title">1.</h2>
                <p className="what-will-p1">We create your course</p>
                <hr className="what-will-hr"></hr>
                <p className="what-will-p2">
                  Our team will create the first
                  version of your learning material
                  from a maximum of 5 pages in
                  Word or 10 pages in PowerPoint.
                  From there, you can make any
                  modification.
                </p>
              </div>
              <div className="col-lg-3">
                <h2 className="title what-will-big-title">2.</h2>
                <p className="what-will-p1">One year support<br />and coaching</p>
                <hr className="what-will-hr"></hr>
                <p className="what-will-p2">
                We help you to edit your own e-learning course for a year, 
                and you have one hour one-to-one coaching session per month 
                in any e-learning subject.
                </p>
              </div>
              <div className="col-lg-3">
                <h2 className="title what-will-big-title">3.</h2>
                <p className="what-will-p1">Pro <br /> workshops</p>
                <hr className="what-will-hr"></hr>
                <p className="what-will-p2">
                  Become a member of the Skillgo
                  community and participate in
                  our private workshops.
                </p>
              </div>
              <div className="col-lg-3">
                <h2 className="title what-will-big-title">4.</h2>
                <p className="what-will-p1">One year usage of Skillgo</p>
                <hr className="what-will-hr"></hr>
                <p className="what-will-p2">
                  You can use all Skillgo features
                  for a year and you can create as
                  many e-learning materials as
                  you want.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="spec-offer" style={{ paddingBottom: '50px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title style-two">
                <h2 className="title wow pixFadeUp">
                  To whom?
                </h2>

                <p className="wow pixFadeUp" data-wow-delay="0.3s" style={{ marginBottom: '50px' }}>
                  For those who want to develop their own courses after
                  just 6-12 hours of learning.
                </p>
                <ul className="list-items wow pixFadeUp" data-wow-delay="0.4s">
                  <li>Teachers</li>
                  <li>Trainers</li>
                  <li>Educators</li>
                  <li>Instructional designers</li>
                </ul>
              </div>

              <Link
                to="/contact"
                className="pix-btn wow pixFadeUp"
                data-wow-delay="0.5s"
              >
                Contact us
              </Link>
            </div>

            <div className="spec-offer-feature-image">
              <div className="image-one" data-parallax='{"y" : 20}'>
                <img
                  src="images/screenshots/screenshot-4-min.png"
                  className="wow pixFadeLeft rounded shadow"
                  alt="spec-offer"
                />
              </div>

              <div className="image-two" data-parallax='{"y" : -20}'>
                <img
                  src="images/screenshots/screenshot-2-min.webp"
                  className="wow pixFadeRight rounded shadow"
                  data-wow-delay="0.3s"
                  alt="spec-offer"
                />
              </div>
            </div>

          </div>
        </div>

        <div className="shape-bg">
          <img src="media/background/shape.png" className="wow fadeInRight" alt="shape-bg" />
        </div>
      </section>

      <section className="spec-offer" style={{ paddingTop: '30px', paddingBottom: '60px' }}>
        <div className="container">
          <div className="col-lg-12 text-center">
            <div className="section-title style-two text-center">
              <h2 className="title wow pixFadeUp">
                The offer
              </h2>
            </div>

            <p className="wow pixFadeUp" data-wow-delay="0.3s" style={{ marginBottom: '50px', fontSize: '18px' }}>
              All that you need to create a cutting-edge e-learning course
            </p>
            <p className="wow pixFadeUp spec-price" data-wow-delay="0.3s" style={{}}>
              800 $
            </p>
            <p className="wow pixFadeUp" data-wow-delay="0.3s" style={{ marginBottom: '30px', fontSize: '18px' }}>
              USD per User / Year
            </p>

            <Link
              to="/contact"
              className="pix-btn wow pixFadeUp"
              data-wow-delay="0.5s"
            >
              Contact us
            </Link>
          </div>
        </div>
      </section>

      <section className="spec-offer">
        <div className="container">
          <div className="row" style={{ flexDirection: 'row-reverse' }}>

            <div className="col-lg-5">
              <div className="section-title style-two">
                <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                  What is the process after order our app?
                </h2>
              </div>

              <div className="description wow pixFadeUp" data-wow-delay="0.7s">
                <ol style={{ fontSize:'18px', fontWeight: '500', lineHeight:'36px', marginBottom: '30px'}}>
                  <li>We contact you</li>
                  <li>You make the payment and download Skillgo</li>
                  <li>You send us your course material</li>
                  <li>We create an e-learning course for you</li>
                  <li>With the Tutorials you can fine-tune it easily</li>
                  <li>If you have any questions we will help you!</li>
                </ol>
                <Link
                  to="/contact"
                  className="pix-btn wow pixFadeUp"
                  data-wow-delay="0.5s"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div>
          <div className="spec-offer-feature-image spec-offer-feature-image-left wow pixFadeRight">
            <div className="image-one" data-parallax='{"y" : 20}'>
              <img
                src="images/screenshots/screenshot-10-min.png"
                className="wow pixFadeLeft rounded shadow"
                alt="spec-offer"
              />
            </div>

            <div className="image-two" data-parallax='{"y" : -20}'>
              <img
                src="images/screenshots/screenshot-5-min.webp"
                className="wow pixFadeRight rounded shadow"
                data-wow-delay="0.3s"
                alt="spec-offer"
              />
            </div>
          </div>

        </div>
        <div className="shape-bg">
          <img src="media/background/shape_bg.png" className="wow fadeInLeft" alt="shape-bg" />
        </div>
      </section>


      <Footer />
    </div>

  )
}

export default Offer;
